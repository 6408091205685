import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SiteItem from "../components/site-item"

const SecondPage = ({ data }) => (
  <Layout>
    <SEO title="All TV Channels Site" description="" />
    <h1 className="text-center">All TV Channels Site</h1>
    <div className="newspapers-grid">
      {data.items.nodes.map(newspaper => (
        <SiteItem
          key={newspaper.frontmatter.id}
          id={newspaper.frontmatter.id}
          url={newspaper.frontmatter.url}
          title={newspaper.frontmatter.title}
          slug={newspaper.fields.slug}
        />
      ))}
    </div>
    {/* <pre>{ JSON.stringify( data.items.nodes, null, 2 ) }</pre> */}
  </Layout>
)

export default SecondPage

export const query = graphql`
  query {
    items: allMarkdownRemark(filter: { frontmatter: { isTv: { eq: true } } }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          id
          title
          url
        }
      }
    }
  }
`
